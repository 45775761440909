<template>
  <header>
    <div>
      <router-link to="/">
        <img class="logo" :src="require('../src/assets/logo.png')" alt="">
      </router-link>
      <div class="nav" :class="isOpen?'open':null">
        <router-link to="/#about">About Us</router-link>
        <router-link @mouseenter="()=>isFocus=true" @mouseleave="()=>isFocus=false" to="/#main" :class="isFocus?'active':null">Products</router-link>
        <router-link to="/solution">Solution</router-link>
        <router-link to="/#contact">Contact Us</router-link>
        <img class="close" @click="()=>{isOpen=false;isFocus=false;}" :src="require('./assets/close.svg')" alt="">
      </div>
      <div v-if="isFocus" class="products"  @mouseenter="()=>isFocus=true" @mouseleave="()=>isFocus=false">
        <router-link v-for="p in products" :to="'/product/'+p" :key="p">{{ p }}</router-link>
      </div>
      <div @click="()=>{isOpen=true;isFocus=true;}" class="mobile-nav">
        <img :src="require('./assets/icon_menu.svg')" alt="">
      </div>
    </div>
  </header>
  <div style="padding-top:70px">
    <router-view/>
  </div>
  <footer>
    <div class="left">
      <p class="copyright">
        © 2022 Hansung Electric.
        All rights reserved.
      </p>
      <p>
        Address: A-1062, 550, Misa-daero, Hanam-si, Gyeonggi-do, Republic of Korea(12925)
        Email: gthkorea@gmail.com
        Phone: +82-10-8688-9084
      </p>
    </div>
    <div class="right">
      <div class="nav">
        <div>
          <router-link to="/about">About Us</router-link>
        </div>
        <div>
          <a  href="javascript:void(0)">Products</a>
          <div class="sub">
            <router-link v-for="p in products"  :to="'/product/'+p" :key="p">{{ p }}</router-link>
          </div>
        </div>
        <div>
          <router-link to="/solution">Solution</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import products from "./products";
export default {
  watch:{
    $route (to){
      if(to.hash){
        setTimeout(()=>{
          let target= document.querySelector(to.hash)
          if(target){
            target.scrollIntoView(true)
          }
        },50)
      }else{
        document.scrollingElement.scrollTo(0,0)
      }
      this.isFocus=false
      this.isOpen=false
    }
  },
  data(){
    return {
      products:products.category.map(v=>v.name),
      isFocus:false,
      isOpen:false,
    }
  }
}
</script>
<style lang="scss">
header{
  background-color: $white;
  position: fixed;
  width: 100%;
  height:70px;
  z-index: 1;
  >div{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    max-width: $max-width;
    margin:0 auto;
    position: relative;
    @include mobile{
      padding:20px 30px;
      width: calc(100% - 60px);
      max-width: none;
    }
    .logo{
      width:162px;
      height: 40px;
      @include mobile{
        width:118px;
        height: 30px;
      }
    }
    .nav{
      @include mobile{
        display: none;
      }
      a{
        display: inline-block;
        width: 206px;
        height: 70px;
        @include mediumContents;
        line-height: 70px;
        text-align: center;
        text-decoration: none;
        color:$black;
      }
      div{
        width: 206px;
      }
      a.active, a:hover{
        color:$white;
        background-color: $black;
        @include mobile{
          background: none;
        }
      }
      .close{
        display: none;
      }
    }
    .nav.open{
      z-index: 2;
      position: fixed;
      top:0;
      right: 0;
      color:$white;
      background-color: #000000cc;
      width: 250px;
      height: 100vh;
      padding-top:50px;
      a{
        color: $white;
        padding-left: 30px;
        width: calc(100% - 30px);
        text-align: left;
      }
      a:nth-child(2){
        margin-bottom: 280px;
      }
      @include mobile{
        display: block;
      }
      .close{
        display: block;
        cursor: pointer;
        position: absolute;
        top:20px;
        right:20px;
      }
    }
    .mobile-nav{
      display: none;

      @include mobile{
        display: block;
      }
    }
    .products{
      position: absolute;
      right:412px;
      top:70px;
      background-color: $black;
      @include mobile{
        top:180px;
        right:0;
        z-index: 3;
        background: none;
      }
      a{
        display: block;
        width: 206px;
        height: 70px;
        @include subContents;
        line-height: 70px;
        text-align: center;
        text-decoration: none;
        color:$white;
        @include mobile{
          width: 200px;
          padding-left: 50px;
          height: 70px;
          text-align: left;
        }
      }
    }
  }
}
footer{
  height: 280px;
  background-color: $black;
  color:$white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .left,.right{
    width: calc($max-width / 2);
  }
  .left{
    padding-top:50px;
    @include mobile{
      padding:50px 30px;
    }
    p{
      @include subContents;
      width:300px;
    }
    p.copyright{
      @include mediumContents;
      width: 200px;
      margin-bottom: 20px;
    }

  }
  .right{
    @include mobile{
      display: none;
    }
    padding-top:50px;
    .nav{
      display: flex;
      justify-content: space-between;
      column-gap: 100px;
      >div{
        a{
          display: block;
          color:$white;
          text-decoration: none;
          margin-bottom: 20px;
        }
      }
      .sub{
        position: absolute;
      }
    }
  }
}
</style>
