<template>
  <div class="home">
    <div id="main" class="main" :style="'background-image:url('+require('../assets/main.png')+')'">
      <div>
        <div class="h1">
          <h1>
            AFFORDABLE,
            RELIABLE,
            SUSTAINABLE.
          </h1>
        </div>
        <div id="product" class="products">
          <h2>
            Products
          </h2>
          <div class="product" v-for="p in products" :key="p" @click="$router.push('/product/'+p)" >
            <span>{{p}}</span>
          </div>
        </div>
      </div>
    </div>
    <div id="about">
      <h2>About Us</h2>
      <div>
        <p>
          With the past 20 years of field experience, HANSUNG ELECTRIC and its
          subsidiaries are proposing countless efforts to manufacture and trade only the most affordable and reliable transmission lines products to our customers.
          HANSUNG ELECTRIC understands the importance electricity has on each
          and one of our lives. Therefore, we will engage in manufacturing and investigating products to our valued customers. Throughout the days ahead of us, HANSUNG ELECTRIC will be on one’s side who are in need of any of our exceptional products.
          give us a call or reach out at gthkorea@gmaill.com for any inquiries on the start and end of a transmission line. We will spare no  effort to become the HANSUNG who always puts our customers first.
        </p>
        <img class="about" :src="require('../assets/logo.png')" alt="">
      </div>
    </div>
    <div id="contact" :style="'background-image:url('+require('../assets/contact.png')+')'">
      <h2>Contact Us</h2>
      <p>If you are interested in our products or have any inquiries on our company, feel free to contact us. <br>
        Email: gthkorea@gmail.com | Phone: +82-10-8688-9084</p>
      <form>
        <div class="row">
          <div>
            <label for="">Name*</label>
            <input v-model="form.name" type="text">
          </div>
          <div>
            <label for="">Phone*</label>
            <input v-model="form.phone" type="text">
          </div>
        </div>
        <div>
          <label for="">Email Address*</label>
          <input v-model="form.email" type="text">
        </div>
        <div>
          <label for="">Inquiry*</label>
          <textarea v-model="form.inquiry" name="" id="" cols="30" rows="10"></textarea>
        </div>
        <button type="button" @click="mail">Submit</button>
      </form>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import products from "../products";

export default {
  name: 'Home',
  data(){
    return {
      products:products.category.map(v=>v.name),
      form:{
        name:'',
        phone:'',
        email:'',
        inquiry:''
      }
    }
  },
  methods:{
    mail(){
      let mail = 'gthkorea@gmail.com'
      if(this.form.name && this.form.phone && this.form.email && this.form.inquiry){
        let xhr = new XMLHttpRequest();
        xhr.open("POST", "//flow-api.data-heroes.kr/api/mail");

        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Content-Type", "application/json");

        xhr.onload = () => {

          alert('Successfully submitted! ')
          this.form.name=''
          this.form.phone=''
          this.form.inquiry=''
          this.form.email=''
        };

        let data = {
          email:mail,
          subject:'Hansung Electric 홈페이지에서 문의가 들어왔습니다.',
          text:'[이름] : '+this.form.name+'\r\n[연락처] : '+this.form.phone+'\r\n[메일주소] : '+this.form.email+'\r\n[질문] : '+this.form.inquiry
        }

        xhr.send(JSON.stringify(data));
      }else{
        alert('Please fill in all information for submitting your inquiry. ')
      }
    }
  }
}
</script>
<style lang="scss">
.main{
  background-size: cover;
  padding:200px 0 50px;
  @include mobile{
    margin-bottom: 436px;
    background-position: center;
  }
  >div{
    height: 550px;
    margin:0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include mobile{
      height: 386px;
      position: relative;
      background-position: center;
    }
    .h1{
      width: $max-width;
      margin:0 auto;
      @include mobile{
        width: 100%;
      }
      h1{
        width:295px;
        @include title;
        @include mobile{
          margin:0 auto;
        }
      }
    }
    .products{
      display: flex;
      justify-content: space-between;
      width: $max-width;
      margin:0 auto;
      @include mobile{
        position: absolute;
        flex-direction: column;
        row-gap: 10px;
        width: calc(100% - 60px);
        top:480px;
        margin:0 auto;
        left:0;
        right:0;
      }
      .product{
        cursor: pointer;
        padding-left: 20px;
        width: 180px;
        height: 100px;
        background-color: $blue;
        color:$white;
        display: flex;
        align-items: center;
        @include titleItem;
        font-style:italic;
        @include mobile{
          width: calc(100% - 20px);
          height: 68px;
        }
        p{

        }
      }
      h2{
        display: none;
        text-align: center;
        @include mobile{
          display: block;
          @include titleItem;
        }
      }
    }
  }
}
#about{
  max-width: $max-width;
  margin:0 auto;
  height: 512px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include mobile{
    width: 100%;
    height: auto;
    margin-bottom: 50px;
  }
  >div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mobile{
      flex-direction: column-reverse;
      height: auto;
    }
    img.about{
      width:316px;
      height:78px;
      @include mobile{
        width: 200px;
        height: 50px;
        margin:30px 0;
      }
    }
    p{
      width: 520px;
      margin-top:30px;
      @include subContents;
      @include mobile{
        width: calc(100% - 60px);
        padding:0 30px;
      }
    }
  }
  h2{
    @include title;
    @include mobile{
      text-align: center;
      @include titleItem;
    }
  }
}
#contact{
  height: 812px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  color:$white;
  @include mobile{
    padding:50px 30px;
    height: auto;
  }
  div{
    text-align: left;
  }
  h2{
    @include title;

    @include mobile{
      @include titleItem;
    }
  }
  p{
    width: 880px;
    margin-top:30px;
    margin-bottom: 50px;
    @include subContents;
    @include mobile{
      width: 100%;
    }
  }
  form{
    width:620px;
    @include mobile{
      width: 100%;
    }
    label{
      display: inline-block;
      margin-top:20px;
      margin-bottom: 10px;
    }
    input{
      width: 100%;
      height: 50px;
    }
    textarea{
      width: 100%;
      height:150px;
    }
    button{
      width: 100%;
      height: 50px;
      background-color: #FEBD00;
      @include contents;
      margin-top: 20px;
      color:$black;
      border:none;
      cursor: pointer;
    }
    .row{
      display: flex;
      justify-content: space-between;
      @include mobile{
        display: block;
      }
      > div{
        width: calc(50% - 10px);
        @include mobile{
          width: 100%;
        }
      }
    }
  }
}
</style>
