<template>
  <div>
    <img style="width: 294px;" :src="require('../../assets/products/D/img_D1.7.png')" alt="">
    <h2>Technical Data</h2>
    <p>
      Rated voltage: 115kV <br>
      Highest voltage for equipment : 145kV <br>
      Rated primary current: 600-800-1200-2000A <br>
      Rated secondary current: lA <br>
      Rated transformation current: Core 1, Core 2, Core 3, Core 4, Core5 600-800-1200-2000/lA <br>
      Rated Frequency: 50/60Hz <br>
      Polarity: Negative <br>
      Accuracy class: 5P20/0.2/0.2/5P20/5P20 Rated output: 30/30/30/30/30VA <br>
      Current error and phase displacement limit <br>
      Measuring windings: The current error and phase displacement at rated frequency        shall not exceed the limit values given according to the corresponding items of the  standard IEC 60044-1 :2003, when the secondary burden is any value form 25% to 100% of the rated burden. (Notes: The secondary burden used for test purpose shall have a power -factor of 0.8 lagging) (Details in the following sheet)
    </p>
    <img :src="require('../../assets/products/D/pd_D1.7.1.png')" alt="">
    <p>
      Protective windings: At rated frequency and with rated burden connected, the current error, phase displacement and composite error shall not exceed the limit values given according to the corresponding items of the standard IEC 60044-1:2003. (Notes: the secondary burden used for test purpose shall have a power-factor of 0.8 lagging) (Details in the following sheet)
      Instrument security factor for metering cores: Fs≤10 <br>
      Accuracy limit factor for metering cores: 20 <br>
      Limits of Temperature rise
    </p>
    <img :src="require('../../assets/products/D/pd_D1.7.2.png')" alt="">
    <p>
      The temperature rise of the current transformer when operating for a long term under rated primary current and rated output, shall not exceed the limiting value shown in the following. <br> a. Temperature rise of the windings: 65K <br> b. Temperature rise of the top layer of oil: 55K <br> c. Temperature rise of exterior terminal (P1,P2) or interface of the windings: 50K.
    </p>
    <h2>Installation Dimensions</h2>
    <img :src="require('../../assets/products/D/pd_D1.7.3.png')" alt="">
  </div>
</template>

<script>
export default {
  name: "D1.7"
}
</script>

<style scoped lang="scss">

h2{
  margin-top:50px;
  color:$blue;
  @include contents;
}
p{
  @include contents;
  text-align: left;
  width: 700px;
  margin:10px auto 0;
  @include mobile{
    width: calc(100% - 40px);
    padding:0 20px;
  }
}
img{
  margin:10px 0;

  @include mobile{
    width: calc(100% - 40px);
    padding:0 20px;
  }
}
</style>
