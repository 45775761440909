<template>
  <div>
    <template v-for="item in list" :key="item.title">
      <h1>{{item.title}}</h1>
      <div class="imgs">
        <img v-for="img in item.img" :src="img" alt="" :key="img" />
      </div>
      <h2>Application</h2>
      <p v-html="parse(item.p)"></p>
    </template>
  </div>
</template>

<script>
export default {
  name: "C3.13",
  data(){
    return {
      list:[
        {
          title:'Composite Hollow Insulator',
          img:[
              require('../../assets/products/C/img_C3.13.1.png'),
            require('../../assets/products/C/img_C3.13.2.png'),
          ],
          p:"Used for high voltage electrical equipment, such as CT, CVT, Transformer, Capacitor, Surge Arrester, Switchgear, Cable Terminal, Voltage Regulator\n" +
              "System Voltage: 33kV, 66kV(69kV), 132kV, 230kV,400KV,500KV,800KV\n" +
              "Specified Cantilever Load (SCL): 4-35 kN\n" +
              "Color: Brown, Gray, or Customer’s requirement\n" +
              "Applicable Standard: IEC, ANSI, or Customer´s requirement\n" +
              "Applicable Condition: environment temperature from -50℃ to +50℃, altitude from 0 ~ 4000 meters above sea level, and medium & heavy pollution area, especially for heavy earthquake areas."
        },
        {
          title:'Composite STATION POST INSULATOR',
          img:[
            require('../../assets/products/C/img_C3.13.3.png'),
          ],
          p:"Used for outdoor substation, distribution device as well as electrical equipment.\n" +
              "System Voltage: 11kV, 33kV, 66kV(69kV), 132kV, 230kV, 400kV, 500kV,800KV\n" +
              "Specified Cantilever Load (SCL): 2-20 kN\n" +
              "Color: Brown, Gray, or Customer’s requirement\n" +
              "Applicable Standard: IEC, ANSI, or Customer´s requirement\n" +
              "Applicable Condition: environment temperature from -50℃ to +50℃, altitude from 0 ~ 4000 meters above sea level, and medium & heavy pollution area, especially for heavy earthquake areas."
        },
        {
          title:'Composite LONG ROD INSULATOR',
          img:[
            require('../../assets/products/C/img_C3.13.4.png'),
          ],
          p:"Used for distribution and high voltage transmission line, applicable for heavy pollution areas\n" +
              "System Voltage: 11kV, 33kV, 66kV(69kV), 132kV, 230kV, 400kV, 500kV,800KV,1000KV\n" +
              "Specified Mechanical Load (SML): 45kN, 70kN, 120kN, 160kN, 210kN, 300kN,400kN,550kN\n" +
              "Color: Brown, Gray, or Customer’s requirement\n" +
              "Applicable Standard: IEC, ANSI, CAN, BS, AS, IS or Customer´s requirement"
        },
        {
          title:'Composite BRACED POST INSULATOR',
          img:[
            require('../../assets/products/C/img_C3.13.5.png'),
          ],
          p:"Used for distribution and high voltage transmission line, applicable for heavy pollution areas\n" +
              "System Voltage: 11kV, 33kV, 66kV(69kV), 132kV, 245kV\n" +
              "Specified Mechanical Load (SML): 1-20 kN\n" +
              "Color: Brown, Gray, or Customer’s requirement\n" +
              "Applicable Standard: IEC, ANSI, CAN, BS, AS, IS or Customer requirement"
        },
      ]
    }
  },
  methods:{
    parse(v){
      return v.replaceAll('\n','<br />').replaceAll('\r','<br />')
    },
  }
}
</script>

<style scoped lang="scss">
h1{
  margin-top:100px;
}
.imgs{
  display: flex;
  justify-content: center;
  gap:50px;
  margin-top:50px;
}
h2{
  margin-top:50px;
  color:$blue;
  @include contents;
}
p{
  @include contents;
  text-align: left;
  width: 700px;
  margin:10px auto 0;
  @include mobile{
    width: calc(100% - 40px);
    padding:0 20px;
  }
}
</style>
