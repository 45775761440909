<template>
  <div class="solution">
    <h1>Our Areas of Expertise</h1>
    <div class="gears">
      <div class="gear" v-for="gear in gears" :key="gear.name">
        <div :style="'background-image:url('+gear.image+')'">

        </div>
        <p>{{gear.name}}</p>
      </div>
    </div>
    <div class="specs">
      <div class="spec" v-for="(spec,i) in specs" :key="spec.title">
        <img :src="spec.image" alt="">
        <div>
          <h3>0{{i+1}}</h3>
          <h2>{{spec.title}}</h2>
          <p v-for="list in spec.list" :key="list">- {{list}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "Solution",
  data(){
    return{
      gears:[
        {
          name:'GIS Substation',
          image:require('../assets/solution1.png')
        },
        {
          name:'Overhead Transmission',
          image:require('../assets/solution2.png')
        },
        {
          name:'Underground Transmission',
          image:require('../assets/solution3.png')
        },
      ],
      specs:[
        {
          title:'Consulting',
          list:[
              'Budgetary price',
              'Project development'
          ],
          image:require('../assets/solution4.png')
        },
        {
          title:'Sourcing',
          list:[
            'GIS',
            'Transformer',
            'Underground cable with accessories',
            'Overhead transmission cable and hardware',
          ],
          image:require('../assets/solution5.png')
        },
        {
          title:'Project Management',
          list:[
            'Coordinate with client',
            'Project scheduling',
            'Material handling',
            'Test & Commissioning',
            'Hand over',
          ],
          image:require('../assets/solution6.png')
        },
        {
          title:'Construction & Installation',
          list:[
            'Substation',
            'Underground cable',
            'Overhead transmission line'
          ],
          image:require('../assets/solution7.png')
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
h1{
  text-align: center;
  @include title;
  margin-top:80px;
  @include mobile{
    margin-top: 50px;
    @include titleItem;
  }
}
.gears{
  display: flex;
  justify-content: center;
  column-gap: 30px;
  margin-top:80px;
  @include mobile{
    margin-top:50px;
    display: block;
    padding:0 30px;
  }
  .gear{
    width: 400px;
    @include mobile{
      width: 100%;
      margin-bottom: 30px;
    }
    >div{
      height: 400px;
      @include mobile{
        height: 300px;
      }
    }
    p{
      background-color: $blue;
      height: 60px;
      color:$white;
      text-align: center;
      line-height: 60px;
      @include titleItem;
      @include mobile{
        @include mediumContents;
      }
    }
  }
}
.specs{
  width: $max-width;
  margin:100px auto;
  @include mobile{
    width: 100%;
    margin-bottom: 0;
  }
  .spec{
    display: flex;
    @include mobile{
      display: block;
    }
    img{
      width:50%;
      @include mobile{
        display: none;
      }
    }
    div{
      background-color: $lightGrey;
      padding-left: 70px;
      width:calc(50% - 70px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      color:$blue;
      @include mobile{
        padding:25px 30px;
        width: calc(100% - 60px);
      }
      h3{
        @include title;
      }
      h2{
        marginTop:20px;
        margin-bottom: 30px;
        @include titleItem;
      }
      p{
        @include contents;
        color:$black;
      }
    }
  }
  .spec:nth-child(even){
    flex-direction: row-reverse;
  }

}
</style>
