<template>
  <div>
    <div class="nav">
      <p>
        <router-link to="/#product">Products</router-link>  <span style="margin:10px"> > </span>  {{category}}
      </p>
    </div>
    <div class="categories" v-if="main">
      <router-link :to="'/product/'+category" :class="sub===null?'active':null">all</router-link>
      <router-link :to="'/product/'+category+'/'+item.code" :class="sub===item.code?'active':null" v-for="item in main.sub" :key="item.code">{{item.name}}</router-link>
    </div>
    <div class="list">
      <div class="product" v-for="(product) in list" :key="product.id" @click="$router.push('/product/'+main.name+'/'+product.code+'/'+encodeURI(product.Title).replaceAll('/','--'))">
        <div class="img" :style="'background-image:url('+product.image+')'">

        </div>
        <div class="p">
          <p>{{product.Title}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import products from "../products";
export default {
name: "Product",
  props:['category','sub'],
  watch:{
    category(){
      this.select=null
    }
  },
  computed:{
    main(){
      if(this.category){
        return products.category.find(v=>v.name.replace('\n','')===this.category.replace('\n',''))
      }else{
        return null
      }
    },
    list(){
      let items = []
      if(this.main){
        let target =  products.items[this.main.code]
        Object.keys(target).filter(v=>{
          if(this.sub){
            return v.split('.')[0]===this.sub
          }else{
            return true
          }
        }).forEach(v=>{
          try{
            items.push({
              id:v,
              image:require('../assets/products/'+this.main.code+'/img_'+v+'.png'),
              ...target[v],
              code:v.split('.')[0]
            })
          }catch (e){
            try{
              items.push({
                id:v,
                image:require('../assets/products/'+this.main.code+'/img_'+v+'.1.png'),
                ...target[v],
                code:v.split('.')[0]
              })
            }catch (f){
              console.log(f)
            }
          }
        })
      }
      return items
    }
  },
  data(){
    return {
      select:null,
    }
  }
}
</script>

<style scoped lang="scss">
.nav{
  background-color: $lightGrey;
  height:88px;
  @include mobile{
    height: auto;
  }
  p{
    max-width: $max-width;
    margin:0 auto;
    line-height: 88px;
    @include contents;
    @include mobile{
      width: calc(100% - 60px);
      padding:24px 30px;
      line-height: 1.2;
    }
  }
}
.categories{
  max-width: $max-width;
  margin:0 auto;
  padding:20px 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 67px;
  row-gap:20px;
  @include mobile{
    width: calc(100% - 60px);
    padding:20px 30px;
    flex-direction: column;
  }
  a{
    text-decoration: none;
    color:$grey;
    @include subContents;
  }
  a.active{
    @include mediumContents;
    color:$black;

  }
}
.list{
  max-width: $max-width;
  margin:100px auto;
  display: flex;
  flex-wrap: wrap;
  gap: 80px;
  @include mobile{
    justify-content: center;
  }
  .product{
    width: 200px;
    box-shadow: 0px 0px 20px 0 $lightGrey;
    .img{
      height: 200px;
      background-size: cover;
    }
    .p{
      height: 60px;
      padding:0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        @include subContents;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 180px;
        max-height: 45px;
      }
    }
  }
}
a{
  text-decoration: none;
  color:$black;
}
</style>
