<template>
  <div>
    <template v-for="(key,j) in Object.keys(product.Extra)" :key="key">
      <h2>{{key}}</h2>
      <template v-for="(item,k) in product.Extra[key]" :key="item">
        <p v-html="parse(item)"></p>
        <img :src="nextImage(j,k)" alt="">
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "C3.12",
  props:['product'],
  data(){
    return {
      i:0
    }
  },
  methods:{
    parse(v){
      let result = v.replaceAll('\n','<br />').replaceAll('\r','<br />')
      let parts = result.split('<br />')

      parts[0] = '<b>'+parts[0]+'</b>'
      return parts.join('<br />')
    },
    nextImage(j,k){
      let i = 1
      let result = 0
      Object.keys(this.product.Extra).forEach((v,first)=>{
        this.product.Extra[v].some((item,seconds)=>{
          if(first===j && k===seconds){
            result= i
            return true
          }
          i++
        })
      })
      try{
        return require('../../assets/products/C/img_C3.12.'+result+'.png')
      }catch (e){
        console.log(e)
      }
    }
  },
  mounted() {
    Object.keys(this.product.Extra).forEach(key=>{
      for(let i  in this.product.Extra[key]){
        console.log(i)
      }
    })

  }
}
</script>

<style scoped lang="scss">
p{
  text-align: left;
  width: 700px;
  margin:10px auto 10px;
  @include contents;
  @include mobile{
    width: calc(100% - 40px);
    padding:0 20px;
  }
}
img{
  margin-bottom: 50px;
  @include mobile{
    width: calc(100% - 40px);
    padding:0 20px;
  }
}
h2{
  color:$blue;
  @include contents;
}
h2:first-child{
  margin-top:50px;
}
</style>
