<template>
<div>
  <p style="text-align: center;margin-bottom: 50px;margin-top:30px">
    <b>Range to:</b> 1core/3cores Applied for: cable supporting and fixing
    rounding Shield
  </p>
  <img :src="require('../../assets/products/C/img_C3.9.png')" alt="">
  <h2>Product Description</h2>
  <p>Cable cleats should be designed to ensure that cables are fixed, supported and routed in a manner that provides safe operation and reduces the risk of damage or injury in the event of an emergency or accident. Improper clamping of cables can result in loss through unnecessary downtime or even injury and death. They should at a minimum:
    <br>
    - Be rated for the specified cable OD.<br>
    - Provide a means of securely fixing the cable.<br>
    - Have adequate strength to secure the cable.<br>
    - Prevent excessive cable movement.<br>
    - Avoid chafing and undue stress in the cable.</p>
  <h2>Cleat Types</h2>
  <p>
    <b>Metallic Two-Part:</b> Again, available in both single-fixing and dual-fixing. LM6 Aluminium construction provides further increased temperature and load ratings allowing both types to carry low, medium and high voltage cable. Fully stackable and designed to mount directly to channel, ladder or any flat surface. Single-fixing for smaller cable ODs of 10-70mm and dual-fixing for ranges of 51-114mm.
  </p>
  <img :src="require('../../assets/products/C/pd_C3.9.1.png')" alt="">
  <p>
    <b>Metallic Trefoil:</b> LM6 Aluminium construction, trefoil configuration. Specifically designed for cleating three single cables in a trefoil configuration where low to medium short circuit withstand levels are required. Available in bot single-fixing and dual-fixing.
  </p>
  <img :src="require('../../assets/products/C/pd_C3.9.2.png')" alt="">
  <p>
    <b>Special Use:</b> Additional styles are available for use in extreme operating conditions. 316 Stainless steel cleats offer superior short circuit withstand levels and corrosion resistance available to suit single, trefoil, quad or bundled cables. Metallic one-part and two-part metallic cleats are also available in cast iron, for the ultimate performance in fire survival situations.
  </p>
  <img :src="require('../../assets/products/C/pd_C3.9.3.png')" alt="">
</div>
</template>

<script>
export default {
name: "C3.9"
}
</script>

<style scoped lang="scss">

h2{
  margin-top:50px;
  margin-bottom: 10px;
  color:$blue;
  @include contents;
}
p{
  width: 700px;
  margin:0 auto;
  text-align: left;
}
img{
  margin-bottom: 50px;
}
</style>
