<template>
  <div>
    <div class="nav">
      <p>
        <router-link to="/#product">Products</router-link> <span style="margin:10px"> > </span> <router-link :to="'/product/'+category">{{category}}</router-link>   <span v-if="subCat" style="margin:10px"> > </span> {{subCat?subCat.name:''}}
      </p>
    </div>
    <div class="container">
      <h1 v-if="id!=='C3.13'">{{product.Title}}</h1>
      <template v-if="product.Extra">
        <C39 v-if="id==='C3.9'"></C39>
        <C312 :product="product" v-else-if="id==='C3.12'"></C312>
        <C313 v-else-if="id==='C3.13'"></C313>
        <D17 v-else-if="id==='D1.7'"></D17>
        <D18 v-else-if="id==='D1.8'"></D18>
      </template>
      <template v-else>
        <p class="standard" v-if="product.Standard" v-html="standardParse(product.Standard,true)"></p>
        <view class="images">
          <img v-for="image in images" :src="image" alt="" :key="image" />
        </view>
        <h2 v-if="product['Product Description'] || pds.length">
          Product Description
        </h2>
        <p class="pd" v-html="standardParse(product['Product Description'])">

        </p>
        <div v-if="pds.length" class="pds">
          <img v-for="pd in pds" :src="pd" alt="" :key="pd" />
        </div>
        <h2 v-if="product['Technical Data'] || tds.length">
          Technical Data
        </h2>
        <p class="td" v-html="standardParse(product['Technical Data'])">

        </p>
        <div v-if="tds.length" class="tds">
          <img v-for="td in tds" :src="td" alt="" :key="td" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import products from "../products";
import C39 from "./extra/C3.9"
import C312 from "./extra/C3.12"
import C313 from "./extra/C3.13"
import D17 from "./extra/D1.7"
import D18 from "./extra/D1.8"
export default {
name: "ProductDetail",
  props:['category','name','sub'],
  components:{C39,C312,C313,D17,D18},
  computed:{
    main(){
      return products.category.find(v=>v.name.replace('\n','')===this.category.replace('\n',''))
    },
    id(){
      let name= decodeURI(this.name).replaceAll('--','/')
      let code = this.main.code
      console.log(name,code)
      return Object.keys(products.items[code]).find(v=>{
        return products.items[code][v].Title===name
      })
    },
    subCat(){
      return this.main.sub.find(v=>v.code===this.sub)
    },
    product(){
      if(this.main){
        return products.items[this.main.code][this.id]
      }
      return null
    },
    images(){
      return this.getImages('img')
    },
    tds(){

      return this.getImages('td')
    },
    pds(){

      return this.getImages('pd')
    }
  },
  methods:{
    standardParse(v,sf=false){
      if(v){
        if(sf){
          return v.replace('Standard:','<b>Standard:</b>')
              .replace('Features:','<b>Features:</b>').replaceAll('\n','<br />').replaceAll('\r','<br />')
        }
        return v.replaceAll('\n','<br />').replaceAll('\r','<br />')
      }else{
        return null
      }
    },
    getImages(v){
      let images = []
      let exists= true
      let count = 1
      try{
        images.push(require('../assets/products/'+this.main.code+'/'+v+'_'+this.id+'.png'))
      }catch (e){
        while(exists){
          try{
            images.push(require('../assets/products/'+this.main.code+'/'+v+'_'+this.id+'.'+count+'.png'))
            count++
          }catch (e){
            exists=false
          }
        }
      }
      return images
    }
  }
}
</script>

<style scoped lang="scss">
.nav{
  background-color: $lightGrey;
  height:88px;
  @include mobile{
    height: auto;
  }
  p{
    max-width: $max-width;
    margin:0 auto;
    line-height: 88px;
    @include contents;
    @include mobile{
      line-height: 1.2;
      padding:24px 30px;
    }
  }
}
.container{
  max-width: 1240px;
  margin:0 auto;
  text-align: center;
  padding: 50px 0 100px;
  .images{
    margin:50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    flex-wrap: wrap;
    @include mobile{
      flex-direction: column;
      padding:30px;
    }

    img{
      width: 380px;
      @include mobile{
        width: 100%;
      }
    }
  }
  p.standard{
    margin-top:30px;
  }
  p.td,p.pd,div.pds,div.tds{
    margin-top:10px;
    @include mobile{
      padding:0 30px;
    }
    img{
      width: 700px;
      margin-top:10px;
      @include mobile{
        width: 100%;
      }
    }
  }
  p.td,p.pd{
    width: 700px;
    margin:10px auto 0;
    text-align: left;
    @include mobile{
      padding:0 30px;
      width: calc(100% - 60px);
    }
  }
  h2{
    margin-top:50px;
    color:$blue;
    @include contents;
  }
}
h1{
  @include title;
  margin:0 auto;
  @include mobile{
    width: calc(100% - 40px);
  }
}
a{
  text-decoration: none;
  color:$black;
}
</style>
